@charset "utf-8";
@import 'normalize';
@import 'variables';
@import 'icon-font';
@import '../../vendor/bootstrap-sass-official/vendor/assets/stylesheets/bootstrap';

////////////////////////////////////////////////////////////////////////////////
// GENERAL STUFFf
////////////////////////////////////////////////////////////////////////////////
@font-face {
    font-family: "SofiaProLight";
    src: url('fonts/SofiaProLight.ttf') format("truetype");
}

@font-face {
    font-family: "SofiaProRegular";
    src: url('fonts/SofiaProRegular.ttf') format("truetype");
}

@font-face {
    font-family: "Monaco";
    src: url('fonts/Monaco.ttf') format("truetype");
}

html, body {
    font-family: 'SofiaProRegular', sans-serif;
    font-weight: 100;
    font-size: 16px;
    color: $main-text;
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @extend %default-font;
    background-color: white;
    height: 100%;
    -webkit-text-size-adjust: none; /* Never autoresize text */
}

////////////////////////////////////////////////////////////////////////////////
// TABLE OF CONTENTS
////////////////////////////////////////////////////////////////////////////////

#toc > ul > li > a > span {
    float: right;
    background-color: #2484FF;
    border-radius: 40px;
    width: 20px;
}

@mixin embossed-bg {
    background: linear-gradient(to bottom, rgba(#000, 0.2), rgba(#000, 0) 8px),
    linear-gradient(to top, rgba(#000, 0.2), rgba(#000, 0) 8px),
    linear-gradient(to bottom, rgba($nav-embossed-border-top, 1), rgba($nav-embossed-border-top, 0) 1.5px),
    linear-gradient(to top, rgba($nav-embossed-border-bottom, 1), rgba($nav-embossed-border-bottom, 0) 1.5px),
    $nav-subitem-bg;
}

.tocify-wrapper {
    font-family: 'SofiaProLight', sans-serif;
    font-weight: 700;
    transition: left 0.3s ease-in-out;
    margin-top: 72px;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    bottom: 0;
    width: $nav-width;
    background-color: $nav-bg;
    font-size: 14px;

    .tocify {
        padding-top: 14.5px;
    }

    a {
        color: #726d65;
        line-height: 28px;
        letter-spacing: -0.3px;
        text-decoration: none;
    }

    a:hover {



    }

    // This is the currently selected ToC entry
    .tocify-focus {
        background-color: $nav-active-bg;
        color: $nav-active-text;
        a {
            color: #3ec7ce;
        }
    }

    // language selector for mobile devices
    .lang-selector {
        display: none;
        a {
            padding-top: 0.5em;
            padding-bottom: 0.5em;
        }
    }

    // This is the logo at the top of the ToC
    & > img {
        display: block;
        max-width: 100%;
    }

    & > .search {
        position: relative;

        input {
            background: $nav-bg;
            border-width: 0 0 1px 0;
            border-color: $search-box-border-color;
            padding: 6px 0 6px 20px;
            box-sizing: border-box;
            margin: $nav-v-padding $nav-padding;
            width: $nav-width - 30;
            outline: none;
            color: $nav-text;
            border-radius: 0; /* ios has a default border radius */
        }

        &:before {
            position: absolute;
            top: 17px;
            left: $nav-padding;
            color: $nav-text;
            //@extend %icon-search;
        }
    }

    img + .tocify {
        margin-top: $logo-margin;
    }

    .search-results {
        margin-top: 0;
        box-sizing: border-box;
        height: 0;
        overflow-y: auto;
        overflow-x: hidden;
        transition-property: height, margin;
        transition-duration: 180ms;
        transition-timing-function: ease-in-out;
        &.visible {
            height: 30%;
            margin-bottom: 1em;
        }

        @include embossed-bg;

        li {
            margin: 1em $nav-padding;
            line-height: 1;
        }

        a {
            color: $nav-text;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .tocify-item > a, .toc-footer li {
        padding: 0 $nav-padding 0 $nav-padding;
        display: block;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    // The Table of Contents is composed of multiple nested
    // unordered lists.  These styles remove the default
    // styling of an unordered list because it is ugly.
    ul, li {
        list-style: none;
        margin: 0;
        padding: 0;
        line-height: 28px;
    }

    li {
        color: $nav-text;
        transition-property: background;
        transition-timing-function: linear;
        transition-duration: 230ms;
    }

    .tocify-header {
        margin-bottom: 22.5px;
        > li > a {
            color: darkgray;
            font-size: 18px;
        }

    }

    // Subheaders are the submenus that slide open
    // in the table of contents.
    .tocify-subheader {
        display: block; // tocify will override this when needed
        background-color: $nav-subitem-bg;
        //.tocify-item > a {
        //    padding-left: $nav-padding + $nav-indent;
        //}

        // for embossed look:
        //@include embossed-bg;
        & > li:last-child {
            box-shadow: none; // otherwise it'll overflow out of the subheader
        }
    }

    .toc-footer {
        padding: 1em 0;
        margin-top: 1em;
        //border-top: 1px dashed $nav-footer-border-color;

        li, a {
            color: $nav-text;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
        }

        li {
            font-size: 0.8em;
            line-height: 1.7;
            text-decoration: none;
        }
    }

}

////////////////////////////////////////////////////////////////////////////////
// PAGE LAYOUT AND CODE SAMPLE BACKGROUND
////////////////////////////////////////////////////////////////////////////////

.page-wrapper {
    margin-left: $nav-width;
    position: relative;
    z-index: 10;
    background-color: white;
    min-height: 100%;

    padding-bottom: 1px; // prevent margin overflow

    // The dark box is what gives the code samples their dark background.
    // It sits essentially under the actual content block, which has a
    // transparent background.
    // I know, it's hackish, but it's the simplist way to make the left
    // half of the content always this background color.
    .dark-box {
        width: $examples-width;
        background-color: $examples-bg;
        position: absolute;
        right: 0;
        top: -30px;
        bottom: 0;
    }

    .lang-selector {
        position: fixed;
        z-index: 50;
        border-bottom: 5px solid $lang-select-active-bg;
    }
}

.lang-selector {
    background-color: $lang-select-bg;
    width: 100%;
    font-weight: bold;
    a {
        display: block;
        float: left;
        color: white;
        text-decoration: none;
        padding: 0 10px;
        line-height: 30px;
        outline: 0;
        background-color: #2d6467;
        border-color: black;
        border-style: solid;
        border-width: 0 1px 0 0;

        &:active, &:focus {
            background-color: #fbf8f5;
            color: $lang-select-pressed-text;
        }

    }

    .active {
        background-color: white;
        color: $lang-select-pressed-text;
    }

    &:after {
        content: '';
        clear: both;
        display: block;
    }
}

////////////////////////////////////////////////////////////////////////////////
// CONTENT STYLES
////////////////////////////////////////////////////////////////////////////////
// This is all the stuff with the light background in the left half of the page

.content {
    // to place content above the dark box
    margin-top: 100px;
    position: relative;
    z-index: 30;
    color: #2d6467;
    line-height: 28px;
    letter-spacing: -0.3px;
    text-decoration: none;

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    & > h1, & > h2, & > h3, & > h4, & > h5, & > h6, & > p, & > table, & > ul, & > ol, & > aside, & > dl {
        margin-right: $examples-width;
        padding: 0 $main-padding;
        box-sizing: border-box;
        display: block;
        //text-shadow: $main-embossed-text-shadow;

        @extend %left-col;
    }

    & > ul, & > ol {
        padding-left: $main-padding + 15px;
    }

    // the div is the tocify hidden div for placeholding stuff
    & > h1, & > h2, & > div {
        clear: both;
    }

    table {
        margin-bottom: 1em;
        overflow: auto;
        table-layout: fixed;
        th, td {
            text-align: left;
            vertical-align: top;
            line-height: 1.6;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            //word-break: break-all;

        }

        th {
            padding: 5px 10px;
            border-bottom: 2px solid #aea497;
            vertical-align: bottom;
        }

        td {
            padding: 10px;
        }

        tr {
            border-bottom: 1px solid #f4ede4;
        }

        tr:last-child {
            border-bottom: none;
        }

        span {
            text-align: center;
            font-family: 'Monaco', sans-serif !important;
            display: table;
            text-align: left;
            padding: 2px 5px;

            &.error {
                background-color: #ffecf2;
            }

            &.success {
                background-color: #ebf9fa;
            }

            &.small {
                font-size: 11px;
                color: #aea497;
                letter-spacing: -0, 3px;
                text-align: left;
            }

        }

        .info {
            background-color: #fbf8f5;
            .info {
                padding-left: 6px;
            }
        }

        &.three-column-table {
            text-align: left;
            span {
                text-align: left;
            }

            th {
                text-align: left;
            }

        }

        &.two-column-table {

            th, td {
                width: 50%;
            }

            th:nth-child(odd) {
                text-align: right;
            }

            th:nth-child(even) {
                text-align: left;
            }

            td:nth-child(odd) {
                text-align: right;
            }

            td:nth-child(even) {
                text-align: left;
            }
        }
    }

    tbody {
        border-bottom: none;
    }

    dt {
        font-weight: bold;
    }

    dd {
        margin-left: 15px;
    }

    p, li, dt, dd {
        line-height: 1.6;
        margin-top: 0;
    }

    img {
        max-width: 100%;
    }

    code {
        background-color: rgba(0, 0, 0, 0.05);
        padding: 3px;
        @extend %break-words;
        @extend %code-font;
    }

    pre > code {
        background-color: transparent;
        padding: 0;
    }
    .code {
        font-family: monospace;
        white-space: pre-wrap;
        padding: 10px;
        background-color: #005f5f;
        color: #ffffff;
    }
    p {
        a {
            color: #3ec7ce;
            text-decoration: none;
        }

        a:hover {
            color: #3ec7ce;
            text-decoration: none;
        }
    }

    .search-highlight {
        padding: 2px;
        margin: -2px;
        border-radius: 4px;
        border: 1px solid #F0E4D3;
        text-shadow: 1px 1px 0 #666;
        background: linear-gradient(to top left, #F7E633 0%, #F1D32F 100%);
    }
}

////////////////////////////////////////////////////////////////////////////////
// CODE SAMPLE STYLES
////////////////////////////////////////////////////////////////////////////////
// This is all the stuff that appears in the right half of the page

.content {

    pre, blockquote {
        background-color: #2d6467;
        color: #fff;

        padding: 5px 10px;
        margin: 0;
        width: $examples-width;

        float: right;
        clear: right;

        box-sizing: border-box;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
        border-width: 0;

        @extend %right-col;

        & > p {
            margin: 0;
        }

        a {
            color: #fff;
            text-decoration: none;
            border-bottom: dashed 1px #ccc;
        }
    }

    pre {
        @extend %code-font;
    }

    pre {
        border-radius: 0;
    }

    blockquote {
        & > p {
            background-color: #2d6467;
            padding: $code-annotation-padding;
            color: #ccc;
            //border-top: 1px solid #000;
            //border-bottom: 1px solid #404040;
        }
    }
}

////////////////////////////////////////////////////////////////////////////////
// RESPONSIVE DESIGN
////////////////////////////////////////////////////////////////////////////////
// These are the styles for phones and tablets
// There are also a couple styles disperesed

@media (max-width: $screen-sm) {
    .tocify-wrapper {
        left: -$nav-width;

        &.open {
            left: 0;
        }
    }

    .page-wrapper {
        margin-left: 0;
    }

    .tocify-wrapper .tocify-item > a {
        padding-top: 0.3em;
        padding-bottom: 0.3em;
    }
}

@media (max-width: $screen-sm) {
    .dark-box {
        display: none;
    }

    %left-col {
        margin-right: 0;
    }

    .tocify-wrapper .lang-selector {
        display: block;
    }

    .page-wrapper .lang-selector {
        display: none;
    }

    %right-col {
        width: auto;
        float: none;
    }

    %right-col + %left-col {
        margin-top: $main-padding;
    }
}

.highlight .c, .highlight .cm, .highlight .c1, .highlight .cs {
    color: #909090;
}

.highlight, .highlight .w {
    background-color: $code-bg;
}

.nav-payconiq {
    background-color: #3ec7ce;
    //background-color: #ff4785
    //height: 72px;
}

.navbar-brand {
    padding-top: 20px;
    padding-bottom: 20px;

    > img {
        height: 30px;
        //padding: 10px;
        width: auto;
    }
}

.navbar-header {
    margin-left: 100px;
}

@media screen and (min-width: $screen-sm) {

    .navbar-header {
        width: 20%;
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
    }

}

.nav {
    > li {
        padding-bottom: 0;
        padding-top: 0;
        padding-left: 28px;
        padding-right: 28px;
        border-bottom: solid 2px #3ec7ce;
        height: 72px;
        &.active-item {
            background-color: #36959a;
            border-bottom: solid 2px #36959a;
        }

        a {
            margin-bottom: 13px;
            margin-top: 13px;
        }
    }

    > li:hover {
        border-bottom: solid 2px #2d6467;
    }
}

.nav.navbar-nav.navbar-right {
    margin-right: 10px;
}

.navbar-default .navbar-nav > li > a {
    color: white;
}

.navbar-fixed-top {
    border-bottom: 0;
}

@media (max-width: $screen-sm) {
    .navbar-header {
        margin-top: 14px;

    }
    .navbar-brand {
        float: right;
        padding-top: 10px;
        padding-bottom: 10px;

        > img {
            height: 15px;
            //padding: 10px;
            width: auto;
        }
    }

    .non-screen-phone {
        display: block;
    }

    .tocify-wrapper {
        margin-top: 70px;
    }
}

.container-fluid {
    height: 72px;
}

.subheader-level-3 > li > a {
    padding-left: 40px !important;
}

.lang-selector {
    display: none !important;
}

a {
    color: #3ec7ce;
    text-decoration: none;
}
a:hover {
    color: #3ec7ce;
    text-decoration: none;
}
//.navbar-default .navbar-toggle .icon-bar {
//    background-color: mediumvioletred; }
.navbar-toggle:hover {
    background-color: #3EB8BF !important;
}
.navbar-toggle:focus {
    background-color: #3EB8BF !important;
}
.navbar-toggle {
    border-color: #3ec7ce !important;
}
