@font-face {
  font-family: 'slate';
  src:font-url('slate.eot?-syv14m');
  src:font-url('slate.eot?#iefix-syv14m') format('embedded-opentype'),
    font-url('slate.woff2?-syv14m') format('woff2'),
    font-url('slate.woff?-syv14m') format('woff'),
    font-url('slate.ttf?-syv14m') format('truetype'),
    font-url('slate.svg?-syv14m#slate') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
    font-family: "SofiaProLight";
    src: url('SofiaProLight.ttf') format("truetype");
}
@font-face {
    font-family: "SofiaProRegular";
    src: url('SofiaProRegular.ttf') format("truetype");
}
@font-face {
    font-family: "Monaco";
    src: url('Monaco.ttf') format("truetype");
}
%icon {
  font-family: 'SofiaProRegular';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

%icon-exclamation-sign {
  @extend %icon;
  content: "\e600";
}
%icon-info-sign {
  @extend %icon;
  content: "\e602";
}
%icon-ok-sign {
  @extend %icon;
  content: "\e606";
}
%icon-search {
  @extend %icon;
  content: "\e607";
}
